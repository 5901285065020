<template>
  <!-- card -->
  <div
    class="
      flex flex-col
      items-center
      justify-start
      px-4
      sm:px-8
      py-2
      rounded-xl
      shadow-xl
      bg-light
      card-container
    "
  >
    <div
      class="grid grid-cols-1 flex-1 w-full py-3 sm:py-6 card-inner-container"
    >
      <!-- video generating view -->
      <div
        class="
          relative
          flex flex-col
          items-stretch
          justify-start
          col-span-2
          lg:col-span-1
        "
      >
        <!-- heading -->
        <h1
          class="
            text-left
            font-display font-bold
            text-base
            sm:text-lg
            md:text-2xl
            text-dark
            mb-2
          "
        >
          Your personalized advertisement is being generated.
        </h1>
        <!-- caption -->
        <h4 class="text-left font-display font-normal text-xs text-dark">
          This might take a moment. Please do not refresh the browser.
          Generating your sponsored ad.
        </h4>
        <!-- video loading progress -->
        <!-- py-32 md:py-40 -->
        <div
          class="
            flex flex-col flex-grow
            items-center
            justify-center
            py-10
            h-full
          "
        >
          <!-- heroicon - progress loader icon -->
          <svg
            v-if="isAPILoading"
            class="
              relative
              animate-spin
              h-12
              sm:h-24
              w-12
              sm:w-24
              mx-auto
              text-dark
              progress-circle
            "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <h6
            class="
              text-center text-dark
              font-display font-bold
              text-xs
              sm:text-xl
              py-4
            "
          >
            {{ msg }}
          </h6>
          <!-- <h5>{{ parseInt(elapsedTime / 1000) }}</h5> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//store
import { mapGetters } from "vuex";

//services
import { CheckUserVideo, SendFeedBack } from "@/services/user.service";

export default {
  name: "GenerateVideo",
  data() {
    return {
      user: null,
      userId: null,
      isAPILoading: false,
      isVideoRendering: true,
      renderCheckInterval: null,
      renderCheckIntervalTime: 3000,
      elapsedTime: 0,
      timer: null,
      msg: "Generating your sponsored ad.",
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "users/getUserDetails",
    }),
  },
  beforeRouteLeave(to, from, next) {
    // if the user tries to go forward, is taken to the next page or manually tries to go to other page while the video is rendering
    if (to.name == "ResultVideo") {
      // if a user is taken to the next page, show them the confirmation dialog
      next();
    }
    // if a user tries to go to any other page, we get a confirmation from them and direct them to the page
    else {
      // confirm and navigate to next view
      if (this.confirmLeave()) {
        next();
      } else {
        next(false);
      }
    }
  },
  async mounted() {
    // shows a confirmation dialog when trying to refresh
    window.onbeforeunload = function (e) {
      e = e || window.event;
      if (e) {
        e.returnValue = "Any string";
      }
      // For Safari
      return "Any string";
      // return false;
    };

    await this.checkIfRendered(
      this.$route.params.campaignName,
      this.$route.params.videoName
    );

    // Start timer
    var startTime = Date.now();
    this.timer = setInterval(() => {
      this.elapsedTime = Date.now() - startTime;
    }, 1000);
  },
  beforeDestroy() {
    // shows a confirmation dialog when trying to refresh
    window.onbeforeunload = function () {
      // e = e || window.event;
      return null;
    };
  },
  methods: {
    // show a native confirmation dialog box when user tries to exit or refresh the window
    confirmLeave() {
      return window.confirm(
        "Are you sure you want to leave this page? This might affect the video generation."
      );
    },

    checkIfRendered(campaignName, videoName) {
      CheckUserVideo(campaignName, videoName)
        .then((res) => {
          const code = res.status;

          if (code == 200) {
            clearInterval(this.timer);
            // TODO Post Elapsed time here
            SendFeedBack(campaignName, videoName, {
              waitTime: this.elapsedTime,
            });
            this.$router.replace(`/result/${campaignName}/${videoName}`);
          } else {
            const maximumWaitTime = 60 * 30 * 1000; /* 15 minutes */
            if (this.elapsedTime > maximumWaitTime) {
              this.$router.replace(`/${this.$router.params.campaignName}`);
            }
            // code == 202
            // Still Processing
            this.renderCheckInterval = setTimeout(
              this.checkIfRendered,
              this.renderCheckIntervalTime,
              campaignName,
              videoName
            );
          }
        })
        .catch((e) => {
          if(e?.response?.status == 500){
            this.isAPILoading = false;
            this.msg = "Sorry something went wrong.";
          }else{
            this.renderCheckInterval = setTimeout(
              this.checkIfRendered,
              this.renderCheckIntervalTime,
              campaignName,
              videoName
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.card-container .card-inner-container {
  height: 50vh;
}
</style>
